var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v("库存列表")]
            )
          ]),
          [
            _c(
              "avue-crud",
              {
                ref: "crud",
                attrs: {
                  option: _vm.setData.tableOpt,
                  data: _vm.tableData,
                  "row-style": _vm.rowStyle,
                  page: _vm.page
                },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "current-change": _vm.currentChange,
                  "row-update": _vm.handleUpdate,
                  "row-del": function($event) {
                    return _vm.del(_vm.row, _vm.index, _vm.done)
                  },
                  "row-save": _vm.handleSave
                },
                scopedSlots: _vm._u([
                  {
                    key: "menuForm",
                    fn: function(ref) {
                      var row = ref.row
                      var index = ref.index
                      var type = ref.type
                      return [
                        type == "add"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-circle-plus-outline",
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.crud.rowSave()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Hold")))]
                            )
                          : _vm._e(),
                        type == "edit"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-circle-check",
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.crud.rowUpdate()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Hold")))]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-circle-close",
                              size: "small"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.crud.closeDialog()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Cancel")))]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.obj,
                  callback: function($$v) {
                    _vm.obj = $$v
                  },
                  expression: "obj"
                }
              },
              [
                _c(
                  "template",
                  {
                    staticStyle: { width: "calc(100% - 80px)" },
                    slot: "menuLeft"
                  },
                  [
                    _c(
                      "el-row",
                      { staticClass: "topFromSearch", attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "fromOutDiv",
                            attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [_vm._v("批号:")]),
                                _c("el-input", {
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.batchNumberVal,
                                    callback: function($$v) {
                                      _vm.batchNumberVal = $$v
                                    },
                                    expression: "batchNumberVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [_vm._v("物料名称:")]),
                                _c("el-input", {
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.materialNameVal,
                                    callback: function($$v) {
                                      _vm.materialNameVal = $$v
                                    },
                                    expression: "materialNameVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", [_vm._v("物料大类:")]),
                                _c("el-input", {
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.materialCategoryVal,
                                    callback: function($$v) {
                                      _vm.materialCategoryVal = $$v
                                    },
                                    expression: "materialCategoryVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", { attrs: { id: "WarehouseSpan" } }, [
                                  _vm._v("仓库:")
                                ]),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "collapse-tags": "",
                                      placeholder: "",
                                      clearable: ""
                                    },
                                    on: {
                                      focus: function($event) {
                                        return _vm.focusDiv($event)
                                      },
                                      blur: function($event) {
                                        return _vm.blurDiv($event)
                                      },
                                      change: _vm.warehousechang
                                    },
                                    model: {
                                      value: _vm.warehouseVal,
                                      callback: function($$v) {
                                        _vm.warehouseVal = $$v
                                      },
                                      expression: "warehouseVal"
                                    }
                                  },
                                  _vm._l(this.CKData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "padding-top": "25px",
                              "text-align": "right",
                              "align-self": "flex-end"
                            },
                            attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  title: _vm.$t("Search"),
                                  icon: "iconfont icon-textile-sousuo",
                                  size: "mini",
                                  type: "primary"
                                },
                                on: { click: _vm.searchFrom }
                              },
                              [_vm._v("  " + _vm._s(_vm.$t("Search")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }